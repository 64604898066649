import React from "react"
import { FacebookIcon } from "./FacebookIcon"
import { GitHubIcon } from "./GitHubIcon"
import { LinkedInIcon } from "./LinkedInIcon"
import { TwitterIcon } from "./TwitterIcon"
import "./icons.scss"

export const SocialIcons = () => {
  return (
    <div className="icons-social">
      <a href="https://www.facebook.com/Deyst-solutions-100128228787995">
        <FacebookIcon />
      </a>
      <a href="https://github.com/deystSolutions">
        <GitHubIcon />
      </a>
      <a href="https://linkedin.com/company/deyst">
        <LinkedInIcon />
      </a>
      <a href="https://twitter.com/DeystS">
        <TwitterIcon />
      </a>
    </div>
  )
}
