import React from "react"
import { Link } from "gatsby"
import { Container } from "../surfaces/Container"
import { SocialIcons } from "../../assets/svg/external/SocialIcons"
import { Paragraph } from "../../components/typogarphy/Paragraph"
import {
  footer,
  linkSection,
  links,
  message,
  smallerLinks,
  copyright,
} from "./footer.module.scss"

export const Footer = () => {
  return (
    <footer className={footer}>
      <Container>
        <div>
          <SocialIcons />
        </div>
        <div className={linkSection}>
          <div className={links}>
            <LinkListItem label="Home" to="/" />
            <LinkListItem label="Services" to="/services" />
            <LinkListItem label="Contact" to="/contact" />
          </div>
        </div>
        <div>
          <Paragraph className={message}>
            As professionals, we care deeply about our impacts to society and
            the environment, and follow the{" "}
            <a
              href="https://www.computer.org/education/code-of-ethics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Institute of Electrical and Electronics Engineers (IEEE) Code of
              Ethics for Software Engineering and Professional Practice.
            </a>
          </Paragraph>
          <div className={smallerLinks}>
            <LinkListItem label="Privacy Policy" to="/privacy" />
            <LinkListItem label="Terms & Conditions" to="/terms" />
          </div>
        </div>
      </Container>

      <span className={copyright}>© Deyst Solutions 2021</span>
    </footer>
  )
}

const LinkListItem = ({ label, to }) => {
  return <Link to={to}>{label}</Link>
}
