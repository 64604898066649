import React from "react"
import {
  NavigationMenu,
  NavigationMenuButton,
} from "./components/NavigationMenu"
import { NavigationMenuItem } from "./components/NavigationMenuItem"
import { NavigationLogo } from "./components/NavigationLogo"
import {
  overlay,
  show,
  hide,
  navbar,
  navbarContainer,
} from "./Navigation.module.scss"

const navigationLinks = [
  {
    to: "/",
    label: "Home",
    key: "home",
  },
  {
    to: "/services",
    label: "Services",
    key: "services",
  },
  // {
  //   to: "/about",
  //   label: "About",
  //   key: "about",
  // },
  {
    to: "/contact",
    label: "Contact",
    key: "contact",
  },
]

export const useMenuExpand = () => {
  const [expand, setExpand] = React.useState(false)
  var [overlayClass, setOverlayClass] = React.useState(overlay)

  const showMenu = () => {
    setExpand(true)
    setOverlayClass(`${overlay} ${show}`)
  }
  const hideMenu = () => {
    setExpand(false)
    setOverlayClass(`${overlay} ${hide}`)
  }

  const toggleMenu = () => {
    if (expand) hideMenu()
    else {
      showMenu()
    }
  }

  return { showMenu, hideMenu, toggleMenu, expand, overlayClass }
}

export const Navigation = ({ location }) => {
  const navRef = React.createRef()
  const { hideMenu, toggleMenu, expand, overlayClass } = useMenuExpand()
  const defaultClass = location.pathname === "/" ? "transparent" : null
  var [navbarclass, setNavbarClass] = React.useState(defaultClass)

  const handleScroll = React.useCallback(() => {
    if (window.pageYOffset > 400 && navbarclass === "transparent") {
      setNavbarClass("")
    } else if (window.pageYOffset < 400 && navbarclass !== "transparent") {
      setNavbarClass("transparent")
    }
  }, [navbarclass])

  React.useEffect(() => {
    if (location.pathname === "/") {
      window.addEventListener("scroll", handleScroll)
    } else {
      setNavbarClass("")
      window.removeEventListener("scroll", handleScroll)
    }
    return () => {
      return window.removeEventListener("scroll", handleScroll)
    }
  }, [location, handleScroll])

  const links = navigationLinks.map(x => (
    <NavigationMenuItem
      key={x.key}
      to={x.to}
      label={x.label}
      location={location}
      onClick={hideMenu}
    />
  ))

  return (
    <>
      <div
        role="presentation"
        aria-label="menu overlay, click to close menu"
        className={overlayClass}
        onKeyDown={hideMenu}
        onClick={hideMenu}
      ></div>
      <nav className={`${navbar} ${[navbarclass]}`} ref={navRef}>
        <div className={navbarContainer}>
          <NavigationLogo location={location} />
          <NavigationMenuButton
            expand={expand}
            onExpand={toggleMenu}
          ></NavigationMenuButton>
          <NavigationMenu expand={expand} location={location}>
            {links}
          </NavigationMenu>
        </div>
      </nav>
    </>
  )
}
