import React from "react"
import { logo, logoPrimary, logoSecondary } from "./branding.module.scss"

export const LogoFullHorizontal = ({ height = "70px" }) => {
  return (
    <svg
      //width="187.85"
      height={height}
      viewBox="0 0 49.702 18.521"
      xmlns="http://www.w3.org/2000/svg"
      className={logo}
    >
      <path
        d="M20.617 14.28v-.217h1.322q.245 0 .245-.238v-.33q0-.2-.185-.238l-.897-.175q-.485-.092-.485-.635v-.221q0-.66.674-.66h1.021l.223.167v.217h-1.24q-.24 0-.24.238v.28q0 .2.185.238l.896.171q.485.096.485.635v.275q0 .66-.678.66h-1.107zm3.386-.493v-1.562q0-.66.674-.66h.995q.678 0 .678.66v1.562q0 .66-.678.66h-.995q-.674 0-.674-.66zm.438.037q0 .238.24.238h.987q.245 0 .245-.238v-1.636q0-.238-.245-.238h-.987q-.24 0-.24.238zm3.424.623v-2.881h.438v2.497h1.39v.384zm3.082-.66v-2.222h.438v2.26q0 .237.24.237h.901q.245 0 .245-.238v-2.259h.437v2.222q0 .66-.678.66h-.91q-.673 0-.673-.66zm3.507-1.837v-.384h2.36v.384h-.96v2.497h-.438V11.95zm3.65 2.497v-2.881h.437v2.88zm1.946-.66v-1.562q0-.66.674-.66h.995q.678 0 .678.66v1.562q0 .66-.678.66h-.995q-.674 0-.674-.66zm.438.037q0 .238.24.238h.987q.245 0 .245-.238v-1.636q0-.238-.245-.238h-.987q-.24 0-.24.238zm3.424.623v-2.881h.48l1.443 2.204v-2.204h.437v2.88h-.48l-1.442-2.204v2.205zm3.781-.167v-.217h1.322q.245 0 .245-.238v-.33q0-.2-.185-.238l-.897-.175q-.485-.092-.485-.635v-.221q0-.66.674-.66h1.021l.223.167v.217h-1.24q-.24 0-.24.238v.28q0 .2.185.238l.896.171q.485.096.485.635v.275q0 .66-.678.66h-1.107z"
        aria-label="SOLUTIONS"
        className={logoPrimary}
        strokeWidth=".047"
      />
      <path
        d="M10.248 1.035C6.716.979 3.393 3.519 2.414 6.898c-1.064 3.335.21 7.261 3.086 9.28 2.787 2.09 6.92 2.038 9.656-.12 2.828-2.087 4.01-6.044 2.864-9.355-1.056-3.296-4.294-5.713-7.772-5.668zm0 .566c3.358-.058 6.502 2.41 7.348 5.645.96 3.253-.467 7.035-3.387 8.781-2.785 1.785-6.71 1.396-9.096-.893-2.64-2.363-3.274-6.578-1.49-9.63C4.93 3.14 7.537 1.572 10.247 1.6z"
        className={logoPrimary}
      />
      <path
        d="M9.249.068C5.716.01 2.394 2.55 1.414 5.93c-1.064 3.335.21 7.26 3.086 9.279 2.787 2.09 6.92 2.04 9.656-.119 2.828-2.087 4.01-6.045 2.864-9.355C15.964 2.44 12.726.023 9.25.068zm0 .565c3.357-.058 6.501 2.41 7.347 5.644.96 3.254-.467 7.036-3.387 8.782-2.785 1.784-6.71 1.396-9.096-.894-2.639-2.362-3.273-6.577-1.49-9.63C3.931 2.171 6.539.605 9.249.633z"
        className={logoSecondary}
      />
      <path
        d="M4.484 9.5c-.06 2.804 2.138 5.456 4.927 5.842 2.677.472 5.478-1.251 6.346-3.812.99-2.598-.139-5.79-2.567-7.156-2.277-1.392-5.465-.87-7.196 1.155A5.956 5.956 0 004.484 9.5zm.565 0c-.06-2.58 2-5.017 4.579-5.301 2.472-.374 5.003 1.316 5.68 3.71.793 2.43-.412 5.321-2.736 6.41-2.183 1.12-5.075.388-6.48-1.619a5.394 5.394 0 01-1.043-3.2z"
        className={logoPrimary}
      />
      <g
        style={{
          fontFeatureSettings: "normal",
          fontVariantCaps: "normal",
          fontVariantLigatures: "normal",
          fontVariantNumeric: "normal",
        }}
        aria-label="DEYST"
        className={logoSecondary}
        strokeWidth=".191"
      >
        <path d="M21.93 8.942V4.073h1.849q1.99 0 1.99 1.99v.89q0 1.99-1.99 1.99zm1.016-.917h.89q.916 0 .916-.918v-1.2q0-.916-.917-.916h-.889zm5.164.917V4.073h3.33v.918h-2.314v.988h2.103v.917h-2.103v1.129h2.314v.917zm5.13-4.678l.12-.19h.868l1.058 2.067 1.059-2.068h.868l.12.191-1.539 2.956v1.722h-1.016V7.22zm5.9 4.311v-.55h2.187q.212 0 .212-.212v-.515q0-.198-.176-.233l-1.376-.275q-.847-.17-.847-1.136v-.395q0-1.186 1.185-1.186h1.616l.473.367v.55h-2.046q-.212 0-.212.212v.459q0 .197.177.233l1.375.275q.847.17.847 1.136v.451q0 1.186-1.185 1.186h-1.757zm5.28-3.584v-.917h3.98v.917h-1.482v3.951h-1.016V4.991z" />
      </g>
      <g className={logoSecondary}>
        <path d="M9.321 2.697c-2.813-.057-5.384 2.235-5.746 5.01-.468 2.73 1.244 5.636 3.882 6.5 2.461.897 5.414-.182 6.762-2.418 1.557-2.371 1.073-5.813-1.093-7.65A5.748 5.748 0 009.32 2.698zm0 .566c2.543-.053 4.865 2.02 5.188 4.528.433 2.511-1.174 5.19-3.627 5.92-2.326.786-5.07-.37-6.175-2.554-1.268-2.28-.572-5.418 1.576-6.91a5.176 5.18 0 013.038-.984z" />
        <path d="M8.493 3.912c-2.814-.056-5.385 2.236-5.747 5.012-.468 2.728 1.245 5.635 3.883 6.497 2.46.897 5.412-.18 6.76-2.416 1.557-2.372 1.073-5.813-1.092-7.65a5.745 5.745 0 00-3.804-1.443zm0 .566c2.543-.052 4.864 2.02 5.186 4.529.433 2.51-1.173 5.19-3.626 5.92-2.326.786-5.07-.37-6.175-2.555-1.268-2.279-.572-5.417 1.577-6.91a5.175 5.18 0 013.038-.983z" />
        <path d="M8.273 1.78C4.74 1.724 1.418 4.263.438 7.642c-1.064 3.335.21 7.26 3.087 9.279 2.786 2.09 6.92 2.04 9.655-.119 2.828-2.087 4.01-6.045 2.864-9.355-1.056-3.296-4.294-5.712-7.772-5.667zm0 .565c3.358-.058 6.502 2.41 7.348 5.644.96 3.254-.467 7.036-3.388 8.782-2.784 1.784-6.71 1.395-9.096-.894C.498 13.514-.136 9.3 1.647 6.247c1.308-2.363 3.916-3.93 6.626-3.902z" />
      </g>
    </svg>
  )
}
