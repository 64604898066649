import React from "react"
import { TransitionGroup, Transition } from "react-transition-group"
import { container } from "./SlideTransition.module.scss"

const timeout = 300

const leftTransition = {
  entering: {
    opacity: 0,
    background: "red",
  },
  entered: {
    opacity: 1,
    transition: "opacity 500ms",
  },
  exiting: {
    opacity: 1,
  },
  exited: {
    opacity: 0,
    transition: "opacity 500ms",
  },
}

export const SlideTransition = ({ children, location }) => {
  const currentKey = location.pathname.split("/")[1] || "/"
  return (
    <TransitionGroup>
      <Transition key={currentKey} timeout={timeout}>
        {status => (
          <div className={container} style={{ ...leftTransition[status] }}>
            {children}
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}
