import React from "react"
import { LogoFullHorizontal } from "../../../../assets/svg/branding/LogoFullHorizontal"
import { logo } from "./NavigationLogo.module.scss"
import { Link } from "gatsby"

export const NavigationLogo = () => {
  return (
    <Link className={logo} to="/">
      <LogoFullHorizontal />
    </Link>
  )
}
