import React from "react"
import "hamburgers/_sass/hamburgers/hamburgers.scss"
import {
  navigationMenu,
  navigationMenuExpand,
  navigationMenuExpanded,
} from "./NavigationMenu.module.scss"

export const NavigationMenu = ({ expand, children }) => {
  const listClassName = expand
    ? `${navigationMenu} ${navigationMenuExpanded}`
    : navigationMenu

  return <ul className={listClassName}>{children}</ul>
}

export const NavigationMenuButton = ({ expand, onExpand }) => {
  return (
    <button
      className={`hamburger hamburger--elastic ${
        expand ? "is-active" : ""
      } ${navigationMenuExpand}`}
      type="button"
      onClick={onExpand}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  )
}
