import React from "react"
import { Link } from "gatsby"
import {
  navigationMenuItem,
  activeLink,
} from "./NavigationMenuItem.module.scss"

export const NavigationMenuItem = ({ to, label, location, onClick }) => {
  return (
    <li className={navigationMenuItem} key={to}>
      <Link
        to={to}
        state={{ prevPath: location.pathname }}
        activeClassName={activeLink}
        onClick={onClick}
      >
        {label}
      </Link>
    </li>
  )
}
