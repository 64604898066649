import React from "react"
import Seo from "./seo"
import { Header } from "./header/header"
import { Content } from "./body/content"
import { Footer } from "./footer/footer"
import "../styles/style.scss"
import { layout } from "./PageLayout.module.scss"
import { SlideTransition } from "./body/SlideTransition"

const PageLayout = ({ location, children }) => {
  const lighter = location.pathname === "/about"

  return (
    <>
      <Seo />
      <div className={layout}>
        <Header siteTitle="Deyst Solutions" location={location} />
        <SlideTransition location={location}>
          <Content light={lighter}>{children}</Content>
          <Footer />
        </SlideTransition>
      </div>
    </>
  )
}

export default PageLayout
